import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel } from 'state-domains/utils/typeCast';
import { buildExportTemplateWithIdUrl } from 'state-domains/network/urls';
import { ExportTemplate } from 'state-domains/domain/subscription';

import { sendDeleteRequestWithXSRFToken } from '../../../utils';

export const deleteExportTemplates = (id: string): Observable<ExportTemplate> => {
    const url = buildExportTemplateWithIdUrl(id);
    return sendDeleteRequestWithXSRFToken(url).pipe(
        switchMap(({ response }: AjaxResponse<ExportTemplate>) => {
            const converted = convertToCamel<ExportTemplate>(response);
            return observableOf(converted);
        }),
    );
};
